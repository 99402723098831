<script setup>
import Button from '@/Components/Button/Button.vue';
import ButtonLink from '@/Components/Button/Link.vue';
import DataCard from '@/Components/DataCard.vue';
import Table from '@/Components/Table/Table.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { useForm } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import DataTableLine from '@/Components/DataTableLine.vue';
import Title from '@/Components/Title/Title.vue';
// Partials

import StatusBar from '@/Components/Invoice/StatusBar.vue';

// Icons
import download from '@i/download-white.svg';
import pencil from '@i/pencil-white.svg';
import plane from '@i/plane-white.svg';

const { t } = useI18n();
const route = inject('route');

const props = defineProps({
    invoice: Object,
});

const tableSettings = [
    { label: t('Name'), width: '35%', cell: 'Bold' },
    { label: t('Quantity'), width: '15%' },
    { label: t('Unit price'), width: '15%' },
    { label: t('VAT'), width: '15%' },
    {
        label: t('Total'),
        width: '15%',
        cell: 'Bold',
    },
];

const tableData = computed(() =>
    props.invoice.invoice_lines.map((line) => {
        return {
            id: line.id,
            rowData: [
                line.description,
                line.quantity,
                '€ ' + line.unit_price / 100,
                line.vat_rate + '%',
                '€ ' + line.amount,
            ],
        };
    })
);

const form = useForm({});

const sendForm = () => {
    form.post(route('staff.open-invoices.store', { invoice_id: props.invoice.id }), {});
};
</script>

<template>
    <StaffLayout :title="$t('Invoices')">
        <Title :title="invoice.number">
            <Button
                :icon="plane"
                :processing="form.processing"
                :recentSuccessful="form.recentSuccessful"
                @click="sendForm"
                class="w-fit"
                :text="$t('Send')"
                v-if="invoice.status !== 'paid'"
            />

            <ButtonLink
                pink
                :icon="pencil"
                :href="route('staff.invoices.edit', { id: invoice.id }) + '/#add-line-button'"
                class="w-fit"
                :text="$t('Edit {model}', { model: $t('Invoice') })"
                v-if="invoice.status === 'concept'"
            />

            <Button
                asA
                orange
                :icon="download"
                :href="route('staff.invoices.download', { id: invoice.id })"
                class="w-fit"
                :text="$t('Download')"
            />
        </Title>

        <div class="flex items-end gap-8">
            <DataCard class="mt-6">
                <p class="font-bold">{{ invoice.location.name }}</p>
                <p>
                    {{ invoice.location.address.street_name }}
                    {{ invoice.location.address.street_number }}{{ invoice.location.address.street_number_addition
                    }}<br />
                    {{ invoice.location.address.postcode }},
                    {{ invoice.location.address.city }}
                </p>
            </DataCard>

            <DataCard class="mt-6">
                <p v-if="invoice.location.phone_number" class="m-0">{{ invoice.location.phone_number }}</p>
                <p v-if="invoice.location.administration_email" class="m-0">
                    {{ invoice.location.administration_email }}
                </p>
            </DataCard>
        </div>

        <StatusBar :invoice="invoice" />

        <Table :simpleLayout="true" :tableSettings="tableSettings" :data="tableData" />

        <div class="self-end w-2/5 mt-6">
            <DataTableLine>
                <p>{{ $t('Total ex. VAT') }}</p>
                <span class="font-bold"> € {{ invoice.total }}</span>
            </DataTableLine>
            <DataTableLine>
                <p>{{ $t('VAT') }}</p>
                <span class="font-bold"> € {{ invoice.vat }}</span>
            </DataTableLine>
            <DataTableLine>
                <p>{{ $t('Total incl. VAT') }}</p>
                <span class="font-bold"> € {{ invoice.total_including_vat }}</span>
            </DataTableLine>
        </div>
    </StaffLayout>
</template>
